/* BRISTOLWEST.COM APPLICATION JAVASCRIPT  */


  // When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
        document.getElementById("myBtn").style.display = "block";
    } else {
        document.getElementById("myBtn").style.display = "none";
    }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}
/*
function createBwCookie() {
  var cname= 'bw_client_sessionid';
  var exdays = 1;
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  var cvalue = (Date.now().toString(36) + Math.random().toString(36) + (exdays * 24 * 60 * 60 * 1000).toString(36)).toUpperCase();
  // console.log('Client Side Session ID Generated::::', cvalue);
  document.cookie = cname + '=' + cvalue + ';' + expires + ';' + 'secure';
  console.log('JS: Client Side Session ID Generated::::', cvalue);
  document.cookie = cname + '=' + cvalue + ';' ;//+ expires + ';' + 'secure';

  console.log('JS: Client Side Generated Cookie :::', document.cookie);
}
*/