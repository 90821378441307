
loadScript("https://www.googletagmanager.com/gtag/js", function() {
    window.dataLayer = window.dataLayer || [];
    window.gtag=function gtag(){dataLayer.push(arguments);}
    });


function loadScript(url, callback){
  // alert('Hello  Now I am tracked using Google Analytics');
  var script = document.createElement("script")
  script.type = "text/javascript";

  if (script.readyState){  //IE
      script.onreadystatechange = function(){
          if (script.readyState == "loaded" ||
                  script.readyState == "complete"){
              script.onreadystatechange = null;
              callback();
          }
      };
  } else {  //Others
      script.onload = function(){
          callback();
      };
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
}

/*
loadScript('https://www.googletagmanager.com/gtag/js?id=<%= GOOGLE_ANALYTICS_ID %>', () => {
 // window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
  // ga('create', 'UA-XXXXX-Y', 'auto'); // moved to service to dynamicall pass the google UAID based on environment

});
*/
